import React, {useCallback, useReducer} from "react";

import ConexContext from "./ConexContext";
import ConexReducer from "./ConexReducer";

//COOKIES
import Cookies from 'js-cookie'

const ConexState = (props) => {
	const initialState = {
		locale: null,
		cookie: null
	};
	const [state, dispatch] = useReducer(ConexReducer, {initialState});
  
	//diccionario de idiomas
	const locES = require('../config/locale/es.json');
	const locEN = require('../config/locale/en.json');

	/**
	* devuelve la traducción de la palabra
	* @param {string} palabra
	*/
	const loc = (palabra)=>{
		switch (state.locale) {
			case 'es':
				return (palabra in locES?locES[palabra]:palabra);
			case 'en':
				return (palabra in locEN?locEN[palabra]:palabra);
			default:
				return (palabra);
		}
	}

  const setLocale=useCallback((token) =>{
		dispatch({ type: 'SET_LOCALE', payload: token});
	},[]);

	/**
	 * Acepta o rechaza las cookies. Guarda la opción en el estado y en una cookie
	 */
	const setCookie=useCallback((accepted) =>{
		dispatch({ type: 'SET_COOKIE', payload: accepted});
		Cookies.set("cookieConsent", accepted);
	},[]);


	return (
		<ConexContext.Provider
			value={{
				locale: state.locale,
				cookie: state.cookie,
				setLocale,
				setCookie,
				loc
			}}
		>
			{props.children}
		</ConexContext.Provider>
	);
};

export default ConexState;