import {
	BrowserRouter,
	Routes,
	Route,
	Navigate
} from "react-router-dom";

import Layout from "./components/Layout";
import { Inicio } from "./components/Inicio";
import { CookiesProp } from "./components/Cookies";
import { Contacto } from "./components/Contacto";
import { Cursos } from "./components/Cursos";
// import { OcaniaFormacion } from "./components/OcaniaFormacion";
// import { QuienesSomos } from "./components/QuienesSomos";
// import { Resultados } from "./components/Resultados";
// import { Testimonios } from "./components/Testimonios";
// import { PropuestaEducacionInfantil } from "./components/PropuestaEducacionInfantil";
// import { SituacionesAprendizaje } from "./components/SituacionesAprendizaje";
// import { SupPracticosInfantil } from "./components/SupPracticosInfantil";
import { Politicadeprivacidad } from "./components/politicadeprivacidad";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/es/inicio" /> } exact />
				<Route path="/:lang" element={<Layout />}>
					<Route index element={<Inicio />} />
					<Route path="/:lang/inicio" element={<Inicio />} />
					<Route path="/:lang/contacto" element={<Contacto />} />
					<Route path="/:lang/cursos" element={<Cursos />} />
					{/* <Route path="/:lang/ocaniaformacion" element={<OcaniaFormacion />} />
					<Route path="/:lang/quienessomos" element={<QuienesSomos />} />
					<Route path="/:lang/resultados" element={<Resultados />} />
					<Route path="/:lang/testimonios" element={<Testimonios />} /> */}
					
					<Route path="/:lang/cookies" element={<CookiesProp/>}/>
					{/* <Route path="/:lang/propuestaeducacioninfantil" element={<PropuestaEducacionInfantil/>}/>
					<Route path="/:lang/situacionesaprendizaje" element={<SituacionesAprendizaje/>}/>
					<Route path="/:lang/suppracticosinfantil" element={<SupPracticosInfantil/>}/> */}
					<Route path="/:lang/politicadeprivacidad" element={<Politicadeprivacidad/>}/>
					
					<Route path="/:lang/*" element={<Navigate to="/es/inicio" /> } exact />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
