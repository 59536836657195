import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import ConexState from './context/ConexState';

//


import './assets/css/bootstrap.css';
import './assets/css/ocana.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';


ReactDOM.render(
	<React.StrictMode>
		<ConexState>
			<App />
    </ConexState>
	</React.StrictMode>,
	document.getElementById('root')
);
