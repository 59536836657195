import React, { useEffect } from 'react';

import ocana from '../assets/img/img4.jpg'
import ocana1 from '../assets/img/img5.jpg'
import ocana2 from '../assets/img/img6.jpg'
import ocana3 from '../assets/img/img7.jpg'
// import arrowgreen from '../assets/img/arrowgreen.png'

export const Cursos = () => {

useEffect(() => {
	document.title = "Cursos";
}, []);

return (
	<>
	<div className='col-12 cabeceraint'>

	</div>
	<main>
	<div className="col-12 item1 fixpad" id="cursos">
				<div className="col-12 ensancha">
					<div className="col-12 fixwidth">
							<h2>Nuestra experiencia a tu servicio</h2>
							<h5>Cursos</h5>
							<p>Si lo tuyo es ir por libre y prefieres comprar todo el material para
								preparártelo poco a poco y a tu ritmo, </p>
							<p>y quieres contar además con nuestros
								consejos y tutorización, estos cursos están hechos para ti.</p>
								
							{/* <ul className='navint'>
									<li className='active'>
										<a href="#" target='_self'>Supuestos Prácticos de Educación Infantil.</a>
									</li>
									<li>
										<a href="#" target='_self'>Propuesta Didáctica en Educación Infantil</a>
									</li>
									<li>
										<a href="#" target='_self'>Situaciones de Aprendizaje en Educación Infantil</a>
									</li>
							</ul> */}
							
					</div>
					<div className="col-12 fixwidth frow">
						<div className="col-6">
							<h2>Curso de Supuestos Prácticos de Educación.</h2>
							<p>¿Quieres aprender a resolver los supuestos prácticos para las
							oposiciones de Educación?</p>

							<p>Te enseñamos cómo resolverlos de manera sencilla y lúdica con
							documentación teórica y práctica, masterclass, corrección y
							tutorización.</p>
							<p>Nuestras preparadoras te guiarán durante todo el proceso. 100% de
							aprobados en esta parte de la prueba escrita y calificaciones de hasta
							10 puntos es la clave de nuestro éxito.</p>
							<p>Nos has encontrado, somos tu mejor opción.</p>
							<p className='precio'>Precio:<span>160€</span></p>
						</div>
						<div className="col-6 img">
							<img src={ocana1} alt="ocaña formación" className="ocanaimg"/>
						</div>
					</div>
					<div className="col-12 fixwidth tright">
						<div className="col-6">
							<h2> Curso Propuesta Pedagógica.</h2>
								<p>¿Quieres aprender a hacer la mejor programación didáctica adaptada a LOMLOE?</p>
								<p>Te enseñamos cómo hacerla con documentación teórica y práctica, masterclass, corrección y tutorización.</p>
								<p>Nuestros preparadores te guiarán durante todo el proceso. Recuerda que tenemos un 100% de aprobados en la parte oral y calificaciones de hasta 10 puntos en este apartado.</p>
								<p>La perfección sí existe si te preparas con nosotros.</p>
							<p className='precio'>Precio:<span>160€</span></p>
						</div>
						<div className="col-6">
							<img src={ocana2} alt="ocaña formación" className="ocanaimg"/>
						</div>
					</div>
					<div className="col-12 fixwidth frow">
						<div className="col-6">
							<h2>Curso de Unidades Didácticas con Situaciones de Aprendizaje adaptados a LOMLOE</h2>
							<p>¿Quieres aprender a realizar unidades didácticas novedosas que incluyan situaciones de aprendizaje constructivistas y adaptadas a las nuevas metodologías?</p>
							<p>Te enseñamos cómo hacerlo con documentación teórica y práctica, masterclass, corrección y tutorización.</p>
							<p>Nuestros preparadores te guiarán durante todo el proceso. Somos el centro de formación con mejor calificación en esta parte de la prueba.</p>
							<p>No lo dudes, confía en nosotros.</p>
							<p className='precio'>Precio:<span>160€</span></p>
						</div>
						<div className="col-6">
							<img src={ocana3} alt="ocaña formación" className="ocanaimg"/>
						</div>
					</div>					
				</div> 
			</div> 
	</main>
	</>
)
}