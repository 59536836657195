const ConexReducer=(state, action) => {
	const { payload, type } = action;
	switch (type) {
		case 'SET_LOCALE':
			return {
				...state,
				locale: payload,
			};
		case 'SET_COOKIE':
			return {
				...state,
				cookie: payload,
			};
		default:
			return state;
	}
};
export default ConexReducer;