import React, { useContext, useEffect, useState } from 'react'
import { NavLink, Outlet, useParams, Link, useLocation } from 'react-router-dom'

//IMG

import accept2 from '../assets/img/accept2.svg'
import noaccept2 from '../assets/img/noaccept2.svg'
// import arrowdown from '../assets/img/desple.png'
// import shop from '../assets/img/shop.png'
import facebook from '../assets/img/facebook.png'
import youtube from '../assets/img/youtube.png'
import x from '../assets/img/x.png'
 
import iconopie1 from '../assets/img/iconopie1.png'
import iconopie2 from '../assets/img/location.png'

import phone from '../assets/img/phone.png'
import email from '../assets/img/email.png'
import logo from '../assets/img/logo.png'
// import slider1 from '../assets/img/slider1.jpg'
import wassap from '../assets/img/logowassap.png'

//CONTEXT
import ConexContext from '../context/ConexContext'
import Cookies from 'js-cookie'

const Layout = () => {
	const location = useLocation();
	const { lang } = useParams();//parametros de la url
	const { setLocale, setCookie, cookie } = useContext(ConexContext);//contexto
	const [submenu1, setsubmenu1] = useState(false);
	// const [submenu2, setsubmenu2] = useState(false);
	// const [tempCookie, setTempCookie] = useState(false);
	const [showModalCookie, setShowModalCookie] = useState(true);
	const [configurarCookie, setconfigurarCookie] = useState(false);
	const [Cookieterceros, setCookieterceros] = useState(false);
	
	const [scroll, setScroll] = useState(false);

	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 10);
		});
	});

	useEffect(() => {
		setShowMenu(false);
	}, [location]);


	/**
	 * Cambia el idioma de la página
	 */
	useEffect(() => {
		setLocale(lang);
	}, [lang, setLocale]);

	/**
	 * Comprueba si se ha aceptado o rechazado las cookies
	 */
	useEffect(() => {
		setCookie(Cookies.get("cookieConsent") === "true" || false);
		// setTempCookie(Cookies.get("cookieConsent") === "true" || false);
		setShowModalCookie(Cookies.get("cookieConsent") === "true"?(false):(true) );
	}, [setCookie]);

	return (cookie === undefined ? null ://si no se ha aceptado o rechazado las cookies no se muestra nada)
		<>
			{configurarCookie &&
				<div className="col-12 panelcookies">
					<div className='col-12 contpanelcookies'>
						<div className="col-12 conborde">
						{/* <div className="col-12 cerrarcookies">
							<button>
								<img src={close} alt="cerrarcookies" />
							</button>
						</div> */}
						<div className="col-12">
							<h3>Gestiona las cookies</h3>
							<p>Continúa navegando, aceptando nuestra <a href="https://www.ujaen.es/politica-de-privacidad" alt="personalizar" title="política de privacidad" target='_blank' rel="noreferrer">política de privacidad</a> y los servicios de terceros.</p>
						</div>
						<div className="col-12 separador">
							<div className="col-12 contseparador">
								<hr />
							</div>
						</div>
						<div className="col-12 centermid">
							{/* <h3>Cookies propias:</h3>	
						<div className='col-12 cookiesacept'>
							<span>Cookie 1</span>
							<button>Permitir</button>
							<img src={accept1} alt="accesono no permitido"/>
							<img src={accept2} alt="accesono permitido"/>
						</div> */}
							<h3>Cookies de terceros:</h3>
							<div className='col-12 cookiesacept'>
								<div className="col-6">
								<span>YouTube</span>
								</div>
								<div className="col-6">
									<button className='aceptar' alt="aceptar" onClick={()=>{setCookieterceros(true)}}>Aceptar</button>
									<button className='noaceptar' alt="rechazar" onClick={()=>{setCookieterceros(false)}}>Rechazar</button>
									{Cookieterceros===true ?(
											<img src={accept2} alt="aceptado" className='accept2'/>
										):(
											<img src={noaccept2} alt="no aceptado" className='accept2'/>
										)
									}
								</div>
							</div>
						</div>
						<div className='col-12 cookiesacept fixmg'>
							{/* {tempCookie ?
								<button className='aceptar' 
									onClick={()=>{
										setCookie(true); 
										setShowModalCookie(false);
									}}
								>
									Aceptar
								</button>
							:	
								<button className='noaceptar'>Cancelar</button>
							} */}
						
						</div>
						<div className="col-12 separador">
							<div className="col-12 contseparador">
								<hr />
							</div>
						</div>
						<div className='col-12 cookiesacept fixmg2'>
						 <button alt="Configurar" onClick={()=>{setconfigurarCookie(false)}}>Guardar configuración</button>
							{/* <button className='noaceptar' alt="rechazar">Rechazar</button> */}
						</div>
						</div>
					</div>
				</div>
			}

			{showModalCookie  &&
				<div className="col-12 cookies">
					<h3>
						<span>Este sitio web usa cookies y te permite controlar las que deseas activar</span>
					</h3>
					{/* <button href="#" alt="personalizar" title='personalizar' onClick={()=>{setShowModalCookie(true)}}>Personalizar</button> */}
						{/* <Link
							to='#'
							onClick={(e) => {
								window.location.href = "https://www.ujaen.es/politica-de-privacidad";
								e.preventDefault();
							}}
						><button href="https://www.ujaen.es/politica-de-privacidad" alt="personalizar" title="política de privacidad">Política de privacidad</button>
						</Link> */}
						<button className='aceptar' alt="aceptar"  onClick={()=>{ setCookie(true); setShowModalCookie(false); Cookies.set("cookieConsent", true);}}>Aceptar</button>
						<button className='noaceptar' alt="rechazar" onClick={()=>{setCookie(false); setShowModalCookie(false); Cookies.set("cookieConsent", false);}}>Rechazar</button>
						<button alt="Configurar" className='configurar' onClick={()=>{setconfigurarCookie(true)}}>Configurar</button>
				</div>
			}

				

			{/* <header className="col-12">
				
					<div className="col-12 logo">
							<img src={logo} alt="logo ocaña formación" />
						</div> 
						
			</header> */}
			<header className={`headerMain ${scroll ? "sticky" : ""}`}>
				<NavLink className="nav-link" id="inicio" to={{ pathname: `/${lang}/inicio` }} onClick={()=>{window.scrollTo(0, 0)}}><img src={logo} alt="logo ocaña formación" className='logo'/></NavLink>

				
				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="container-fluid">
						<img src={logo} alt="" className='logomovil'/>
						<button className={`navbar-toggler collapsed ${showMenu ? 'show' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>{setShowMenu(!showMenu)}}>
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className={`collapse navbar-collapse ${showMenu ? 'show' : ''}`} id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0">
							<li className="nav-item nmovil">
								<NavLink to={{ pathname: `/${lang}/inicio` }} onClick={()=>{window.scrollTo(0, 0);setShowMenu(false)}}>Inicio</NavLink>
								{/* <a className="nav-link" aria-current="page" href="#">Ocaña formación</a> */}
							</li>
							{/*<li className="nav-item">
								<NavLink to={{ pathname: `/${lang}/ocaniaformacion` }} onClick={()=>{window.scrollTo(0, 0)}}>Ocaña formación</NavLink>
							* <a className="nav-link" aria-current="page" href="#">Ocaña formación</a> 
							</li>*/}
							<li className="nav-item nmovil dropdown">
							
								
								<span className={submenu1 ? ('nav-item formabot activasubmenu') : ('nav-item formabot')} onFocus={() => { setsubmenu1(true) }} onMouseOver={() => { setsubmenu1(true) }} onMouseLeave={() => { setsubmenu1(false) }} >
									<NavLink className="nav-link" to={{ pathname: `/${lang}/cursos` }} onClick={()=>{window.scrollTo(0, 0);setShowMenu(false)}}>Cursos </NavLink>
										{/* <a className="nav-link active" aria-current="page" href="#">
											Cursos 
											<img src={arrowdown} alt="despliega" className='despliega'/>
										</a> */}
										{/* <ul className='sub-menu'>
											<li className="active">
												<a href="#" target='_self' className='active'>Modelos de preparación</a>
											</li>
											<li>
												<a href="#" target='_self'>Cursos</a>
											</li>
										
										
										</ul> */}
									</span>
							</li>
					
							{/* <li className="nav-item meinteresa">
								<a className="nav-link" aria-current="page" href="#">Me interesa <img src={shop} alt="me interesa"/></a>
							</li> */}
							<li className="nav-item nmovil">
								<NavLink className="nav-link" to={{ pathname: `/${lang}/contacto` }} onClick={()=>{window.scrollTo(0, 0);setShowMenu(false)}}>Contacto</NavLink>
								{/* <a className="nav-link" aria-current="page" href="#">Contacto</a> */}
							</li>
							
							
						</ul>
					
						</div>
					</div>
					</nav>
			</header>

					
		

			<main className="col-12">
				<Outlet />
				<button className='wassap'><a href="https://wa.me/+34699816198" target='_black'><img src={wassap} alt="" /></a></button>
			</main>
			<footer>
				<div className="cont_footer">
					<div className='col-12'>
						<div className='col-3'>
							<img src={iconopie2} alt=""/>
							<h4>Contacta con nosotros:</h4>
							<ul>
								<li>Calle Profesor Alfonso Sancho Sáez, 10 (23006, Jaén)</li>
								<li><img src={phone} alt="teléfono"/><a href="tel:+34699816198" target="self"> tel: +34 699 81 61 98</a></li>
								<li><img src={email} alt="email"/><a href="mailto: info@ocañaformación" target="self"> info@ocañaformación</a></li>
							</ul>
						
						</div>
						<div className='col-3'>
							<img src={logo} className="logopie" alt="ocaña formación" />
							{/* <h4>Síguenos en:</h4>
							<ul className="listarrss">
								<li><Link to="https://www.facebook.com/"><img src={facebook} alt="facebook"/></Link></li>
								<li><Link to="https://twitter.com/"><img src={x} alt="x"/></Link></li>
								<li><Link to="https://www.youtube.com/"><img src={youtube} alt="youtube"/></Link></li>
							</ul> */}
							<ul className='listalegal'>
								<li><NavLink to={{ pathname: `/${lang}/politicadeprivacidad` }} onClick={()=>{window.scrollTo(0, 0)}}>Politica de privacidad</NavLink></li>
								{/* <li>Politica de privacidad</li> */}
								<li>|</li>
								<li><NavLink to={{ pathname: `/${lang}/cookies` }} onClick={()=>{window.scrollTo(0, 0)}}>Cookies</NavLink></li>
								{/* <li>Cookies</li> */}
							
							</ul>
						</div>
						<div className='col-3'>
							<img src={iconopie1} alt=""/>
							<h4><NavLink to={{ pathname: `/${lang}/mapa_web` }} onClick={()=>{window.scrollTo(0, 0)}}>Mapa web</NavLink></h4>
							<ul>
								{/* <li><NavLink to={{ pathname: `/${lang}/ocaniaformacion` }} onClick={()=>{window.scrollTo(0, 0)}}>Ocaña formación</NavLink></li> */}
								{/* <li>Ocaña formación</li> */}
								<li><NavLink to={{ pathname: `/${lang}/cursos` }} onClick={()=>{window.scrollTo(0, 0)}}>Cursos</NavLink></li>
								{/* <li>Cursos</li> */}								
								{/* <li><NavLink to={{ pathname: `/${lang}/quienessomos` }} onClick={()=>{window.scrollTo(0, 0)}}>Quiénes somos</NavLink></li>
								<li>Quienes somos</li>
								<li><NavLink to={{ pathname: `/${lang}/testimonios` }} onClick={()=>{window.scrollTo(0, 0)}}>Testimonios</NavLink></li>
								<li>Testimonios</li> */}
								<li><NavLink to={{ pathname: `/${lang}/contacto` }} onClick={()=>{window.scrollTo(0, 0)}}>Contacto</NavLink></li>
								{/* <li>Contacto</li> */}

							</ul>
						
						</div>
						


					</div>
				</div>
			</footer>
		</>
	)
}

export default Layout