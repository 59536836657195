	import React, { useEffect } from 'react';

export const Contacto = () => {

useEffect(() => {
	document.title = "Contacto";
}, []);

return (
	<>
	<div className='col-12 cabeceraint'>

	</div>
	<main>
		<div className="col-12 item2" id="contacto">
				<div className="col-12">
						<h2 className='verde'>
							Contacto
						</h2>
						<p>Contacta con nosotros mediante <a href="tel:+34699816198">WhatsApp o llamada</a>, mediante <a href= "mailto: info@ocañaformacion.es">correo electrónico</a>,</p> 
						<p>o nos puedes visitar en Jaén, en la calle Profesor Alfonso Sancho Sáez, 10 (23006, Jaén).</p>
				</div>
				<div className='col-12 mapacontacto'>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.462890982287!2d-3.795519822849301!3d37.779190411903684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6dd70f8d110a37%3A0xc0b58df4cae21d05!2sC.%20Prof.%20Alfonso%20Sancho%20S%C3%A1ez%2C%2010%2C%2023005%20Ja%C3%A9n!5e0!3m2!1ses!2ses!4v1715590359350!5m2!1ses!2ses" width="100%" height="350" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
				</div>				
		</div>
	</main>
	</>
)
}